<template>
  <fieldset>
    <DocFields
      :modelValue="data"
      :document-type-id="documentTypeId"
      :document-reg-num="documentRegNum"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #additional-fields>
        <UniversalSelectField
          :options="kindsOfEngIz"
          :modelValue="data.kindsOfEngIz"
          @update:modelValue="update('kindsOfEngIz', $event, v$.kindsOfEngIz)"
          :isMultiSelect="true"
          :errors="v$.kindsOfEngIz.$errors"
          data-qa="typeNaturalStudy"
        >
          <label class="label label--required">Вид изученности природных и техногенных условий территории</label>
        </UniversalSelectField>
        <SubjectFieldWithEntrepreneur
          :modelValue="data.organizationWhoPrepared"
          @update:modelValue="
            update(
              'organizationWhoPrepared',
              $event,
              v$.organizationWhoPrepared
            )
          "
          :errors="v$.organizationWhoPrepared.$errors"
          data-qa="providingOrganization"
        >
          <template #label>
            <label class="label label--required"
              >Организация, выполнившая подготовку</label
            >
          </template>
        </SubjectFieldWithEntrepreneur>
      </template>
    </DocFields>
  </fieldset>
</template>

<script>
import DocFields from "./DocFields.vue";
import { toRefs } from "vue";
import { helpers, required } from "@vuelidate/validators";
import useValidator from "./validator";
import DocSection8 from "@/models/documents/docSection8";
import useDocRefKindEngIzDirectoryRef from "@/components/RegistrationProcess/RegistrationDocumentFields/hooks/docRefKindEngIzHook";
import subjectValidators from '../../../models/subjectValidators';
import UniversalSelectField from "@/components/basic/form/select/UniversalSelectField.vue";
import SubjectFieldWithEntrepreneur from "../../basic/form/SubjectFieldWithEntrepreneur.vue";

export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => new DocSection8(),
    },
    documentTypeId:{
      type: Number,
      required: true
    },
    documentRegNum:{
      type: String,
      required: false
    }
  },
  components: {
    UniversalSelectField,
    DocFields,
    SubjectFieldWithEntrepreneur
},
  setup(props, { emit }) {
    const { modelValue: data } = toRefs(props);
    const { value: kindsOfEngIz } = useDocRefKindEngIzDirectoryRef();

    function update(fieldName, value, field) {
      const newValue = { ...props.modelValue };
      newValue[fieldName] = value;
      if (field) field.$model = value;
      emit("update:modelValue", newValue);
    }

    let rules = {
      kindsOfEngIz: {
        required: helpers.withMessage("Заполните поле", required),
      },
      organizationWhoPrepared: {
        required: helpers.withMessage("Заполните поле", required),
        ...subjectValidators
      },
    };

    const v$ = useValidator(data, rules);

    return { data, update, kindsOfEngIz, v$ };
  },
};
</script>