<template>
  <UniversalSelectField
    :modelValue="modelValue"
    :keyProp="'id'"
    :displayProp="displayProp"
    @filter="updateFilterWithDelay"
    @update:modelValue="onSelectItem"
    @firstRowClick="onCreateNewSubject"
    :hasError="errors.length > 0"
    :canFind="true"
    :options="subjectReferences"
    :isFirstRowFull="true"
    class="test"
    v-bind="$attrs"
  >
    <slot name="label"></slot>
    <template #title>
      <div class="subject-select" v-if="mode === 'all'">
        <div class="subject-select__radio-and-label">
          <input
            class="subject-select__radio"
            type="radio"
            :id="'Enterpreneur'+groupId"
            :name="'subject'+groupId"
            value="Enterpreneur"
            v-model="picked"
          />
          <label class="subject-select__label" :for="'Enterpreneur'+groupId"
            >Индивидуальный предприниматель</label
          >
        </div>
        <div class="subject-select__radio-and-label">
          <input
            class="subject-select__radio"
            type="radio"
            :id="'Organization'+groupId"
            :name="'subject'+groupId"
            value="Organization"
            data-qa="Organization"
            v-model="picked"
          />
          <label class="subject-select__label" :for="'Organization'+groupId"
            >Юридическое лицо</label
          >
        </div>
      </div>
    </template>
    <template #infoButton>
      <button
        @mouseup="onInfoSubjectClick"
        class="button button-icon"
        :title="getTooltip"
        :disabled="isSubjectInfoInactive"
        data-qa="personInfo"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="10"
            cy="10"
            r="7.5"
            stroke="#90A0B7"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10 10V13.3333"
            stroke="#90A0B7"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10 6.66675H10.0083"
            stroke="#90A0B7"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </template>
    <template #removeButton>
      <slot name="removeButton"></slot>
    </template>
    <template #firstRow>
      <div class="add-subject">
        <svg
          class="add-subject__icon"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 1V11"
            stroke="#90A0B7"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1 6L11 6"
            stroke="#90A0B7"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Добавить субъект
      </div>
    </template>
    <template v-slot:dropdownMenu="{ options, focused, doHide }">
      <li
        class="dropdown__item-group"
        v-if="getActualSubjects(options).length > 0"
      >
        Актуальные
      </li>
      <li
        @mousedown="onSelectItem(opt, doHide)"
        class="dropdown__item"
        v-for="(opt, index) in getActualSubjects(options)"
        :key="opt['id']"
        :class="{focused: focused === index + 1 }"
      >
        <div class="">
          <div class="subjects__display-name">{{ opt.displayName }}</div>
          <div class="subjects__description">{{ formatSubjectInfo(opt) }}</div>
        </div>
      </li>
      <li
        class="dropdown__item-group"
        v-if="getInactualSubjects(options).length > 0"
      >
        Неактуальные
      </li>
      <li
        @mousedown="onSelectItem(opt, doHide)"
        class="dropdown__item"
        v-for="(opt, index) in getInactualSubjects(options)"
        :key="opt['id']"
        :class="{focused: focused === index + 1 }"
      >
        <div class="">
          <div class="subjects__display-name">{{ opt.displayName }}</div>
          <div class="subjects__description">{{ formatSubjectInfo(opt) }}</div>
        </div>
      </li>
    </template>
    <template #validation>
      <ValidationMsg :errors="errors"></ValidationMsg>
    </template>
  </UniversalSelectField>


  <modal-window :visible="isModalWindowVisible" @close="closeModal">
    <template #title v-if="isCreate">Создание субъекта</template>
    <template #title v-else>Информация о субъекте</template>
    <EnterpreneurFields
      v-model="enterpreneurModel"
      v-if="isPersonModalWindowVisible"
      :validator="enterpreneurValidator"
      :isReadonly="isReadonlySubject"
    ></EnterpreneurFields>
    <OrganizationFields
      v-model="organizationModel"
      v-if="isOrganizationModalWindowVisible"
      :validator="organizationValidator"
      :isReadonly="isReadonlySubject"
    ></OrganizationFields>
    <template v-if="!isReadonlySubject" #footer>
      <div class="group">
        <button class="button button--light group__item" @click="closeModal">
          Отмена
        </button>
        <button
          class="button button--primary group__item"
          @click="addNewSubject"
        >
          Сохранить
        </button>
      </div>
    </template>
  </modal-window>

  <modal-window :visible="isSubjectHasDuplicates" @close="closeDuplicateModal">
    <template #title>Сохранение субъекта</template>
    <div class="duplicates__info">
      В системе уже существуют субъекты с аналогичными данными. Сохранить новый
      субъекта как "Актуальный", а статус уже существующих заменить на
      "Неактуальный"?
    </div>
    <div class="subjects__info">
      <ul class="subjects__list" v-if="isPersonSubjectsList">
        <li
          class="list__element"
          v-for="subj in subjectDuplicates"
          :key="subj.externalId"
        >
          <SubjectDuplicateField
            :chosenSubject="subj"
          />
        </li>
      </ul>
      <div class="subjects__info">
        <ul class="subjects__list" v-if="isOrganizationSubjectsList">
          <li
            class="list__element"
            v-for="subj in subjectDuplicates"
            :key="subj.externalId"
          >
            <SubjectDuplicateField
              :chosenSubject="subj"
            />
          </li>
        </ul>
      </div>
    </div>
    <template #footer>
      <div class="group">
        <button
          class="button button--light group__item"
          @click="closeDuplicateModal"
        >
          Отмена
        </button>
        <button
          class="button button--primary group__item"
          @click="makeNewSubjectActual"
        >
          Сохранить
        </button>
      </div>
    </template>

    
  </modal-window>
</template>

<script>
import { computed, ref } from "vue";
import useSubjectReference from "../../../hooks/subjectReference";
import useSubject from "../../../hooks/subject";
import EnterpreneurFields from "../../subject/EnterpreneurFields";
import OrganizationFields from "../../subject/OrganizationFields";
import { SUBJECT_STATUS } from "@/models/subjStatus";
import useEnterpreneurValidator from "../../subject/enterpreneurValidator";
import useOrganizationValidator from "../../subject/organizationValidator";
import SubjectDuplicateField from "./SubjectDuplicateField.vue";
import ValidationMsg from "../../basic/form/select/ValidationMsg";
import UniversalSelectField from "@/components/basic/form/select/UniversalSelectField.vue";

export default {
  props: {
    modelValue: {
      required: true,
    },
    displayProp: {
      default: () => "displayName",
      type: String,
    },
    mode: {
      type: String,
      default: () => "all",
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  components: { UniversalSelectField, OrganizationFields, EnterpreneurFields, SubjectDuplicateField, ValidationMsg },
  emits: ["update:modelValue", "showModal"],
  setup(props, { emit }) {
    const pickedType =
      props.mode !== "all"
        ? ref(props.mode)
        : props.modelValue && props.modelValue.type
        ? ref(props.modelValue.type)
        : ref("Enterpreneur");
    const picked = computed({
      get: () => {
        if (
          props.modelValue === null ||
          props.modelValue === undefined ||
          props.modelValue.type === null ||
          props.modelValue.type === undefined
        )
          return pickedType.value;
        return props.modelValue.type;
      },
      set: (value) => {
        pickedType.value = value;
        emit("update:modelValue", {});
      },
    });
    const groupId = computed({
      get: () => {
        return Date.now();
      }
    })

    const isReadonlySubject = ref(false);
    const isCreate = ref(false);
    const subjectReferenceHook = useSubjectReference();

    const subjectReferences = computed(() => {
      if (pickedType.value === "Enterpreneur")
        return subjectReferenceHook.entrepreneurs.value;
      if (pickedType.value === "Organization")
        return subjectReferenceHook.organizations.value;
      return [];
    });

    function getActualSubjects(subjects) {
      return subjects.filter((x) => x.subjectStatus.id !== SUBJECT_STATUS.inactual.id);
    }

    function getInactualSubjects(subjects) {
      return subjects.filter((x) => x.subjectStatus.id === SUBJECT_STATUS.inactual.id);
    }

    function formatSubjectInfo(subject) {
      let result = [];

      if (subject.type === "Enterpreneur") {
        if (subject.passportSeries && subject.passportNumber)
          result.push(
            "Паспорт: " + subject.passportSeries + " " + subject.passportNumber
          );

        if (subject.inn) result.push("ИНН: " + subject.inn);

        if (subject.ogrnip) result.push("ОГРНИП: " + subject.ogrnip);
      } else {
        if (subject.inn) result.push("ИНН: " + subject.inn);

        if (subject.ogrn) result.push("ОГРН: " + subject.ogrn);
      }

      return result.join(", ");
    }

    function updateFilterWithDelay(value) {
      if (typeof value === "string") {
        emit("update:modelValue", null);
      } else emit("update:modelValue", value);
      subjectReferenceHook.fetchWithDelay(value);
    }
    function onSelectItem(item, doHide) {
      if (item === null) return emit("update:modelValue", null);
      if (pickedType.value === "Enterpreneur") emit("update:modelValue", item);
      if (pickedType.value === "Organization") emit("update:modelValue", item);
      if(doHide) {
        doHide();
      }
    }
    const {
      enterpreneurModel,
      organizationModel,
      createEnterpreneur,
      createOrganization,
      clearModels,
      makePersonsInactive,
      makeOrganizationsInactive,
    } = useSubject();

    const enterpreneurValidator = useEnterpreneurValidator(enterpreneurModel);
    const organizationValidator = useOrganizationValidator(organizationModel);

    async function onInfoSubjectClick() {
      if (pickedType.value === "Enterpreneur") enterpreneurModel.value = props.modelValue;
      if (pickedType.value === "Organization")
        organizationModel.value = props.modelValue;

      isCreate.value = false;
      isReadonlySubject.value = true;
      onShowModal();
    }

    //#region Modal
    const getTooltip = computed(() => {
      if (
        props.modelValue?.externalId === null ||
        props.modelValue?.externalId === undefined
      )
        return "Выберите субъект, чтобы посмотреть сведения о нем";
      else return "Показать сведения о субъекте";
    });

    const isSubjectInfoInactive = computed(() => {
      if (props.modelValue?.externalId) return false;
      else return true;
    });

    const isModalWindowVisible = computed(
      () =>
        isPersonModalWindowVisible.value ||
        isOrganizationModalWindowVisible.value
    );
    const isPersonModalWindowVisible = ref(false);
    const isOrganizationModalWindowVisible = ref(false);
    const isSubjectHasDuplicates = ref(false);
    const isPersonSubjectsList = ref(false);
    const isOrganizationSubjectsList = ref(false);
    const subjectDuplicates = ref([]);
    const chosenSubject = ref(null);


    function onCreateNewSubject() {
      isReadonlySubject.value = false;
      isCreate.value = true;
      clearModels();
      onShowModal();
    }

    function onShowModal() {
      if (pickedType.value === "Enterpreneur") {
        isPersonModalWindowVisible.value = true;
        isOrganizationModalWindowVisible.value = false;
      } else {
        isOrganizationModalWindowVisible.value = true;
        isPersonModalWindowVisible.value = false;
      }
    }

    function showSubjectDuplicates(subjectType) {
      if (subjectType === "Enterpreneur") 
        isPersonSubjectsList.value = true;

      if (subjectType === "Organization")
        isOrganizationSubjectsList.value = true;

      isSubjectHasDuplicates.value = true;
    }

    function closeModal() {
      isPersonModalWindowVisible.value = false;
      isOrganizationModalWindowVisible.value = false;
    }

    function closeDuplicateModal() {
      isPersonSubjectsList.value = false;
      isOrganizationSubjectsList.value = false;
      isSubjectHasDuplicates.value = false;
    }

    function makeNewSubjectActual() {
      let duplicatesExternalIds = subjectDuplicates.value.map(function (x) {
        return x.externalId;
      });

      if (pickedType.value === "Enterpreneur") {
        makePersonsInactive(duplicatesExternalIds).then((response) => {
          if (response.isSuccess) {
            createEnterpreneur().then((subjectResult) => {
              if (subjectResult.isSuccess) {
                closeDuplicateModal();
                onSelectItem(subjectResult.value);
                subjectReferenceHook.fetch();
              }
            });
          }
        });
      } else {
        makeOrganizationsInactive(duplicatesExternalIds).then((response) => {
          if (response.isSuccess) {
            createOrganization().then((subjectResult) => {
              if (subjectResult.isSuccess) {
                closeDuplicateModal();
                onSelectItem(subjectResult.value);
                subjectReferenceHook.fetch();
              }
            });
          }
        });
      }
    }

    function addNewSubject() {
      if (isPersonModalWindowVisible.value) {
        enterpreneurValidator.value.$touch();
        if (enterpreneurValidator.value.$invalid) return;

        createEnterpreneur()
          .then((subjectResult) => {
            if (subjectResult.isSuccess) {
              closeModal();
              onSelectItem(subjectResult.value, "Enterpreneur");
              subjectReferenceHook.fetch();
            }
          })
          .catch((err) => {
            if (err.response?.status === 409) {
              closeModal();
              subjectDuplicates.value = err.response?.data;
              showSubjectDuplicates("Enterpreneur");
            }
          });
      }

      if (isOrganizationModalWindowVisible.value) {
        organizationValidator.value.$touch();
        if (organizationValidator.value.$invalid) return;

        createOrganization()
          .then((subjectResult) => {
            if (subjectResult.isSuccess) {
              if (subjectResult.isSuccess) {
                closeModal();
                onSelectItem(subjectResult.value, "Organization");
                subjectReferenceHook.fetch();
              }
            }
          })
          .catch((err) => {
            if (err.response?.status === 409) {
              closeModal();
              subjectDuplicates.value = err.response?.data;
              showSubjectDuplicates("Organization");
            }
          });
      }
    }
    //#endregion

    return {
      subjectReferenceHook,
      picked,
      updateFilterWithDelay,
      onSelectItem,
      //modal
      onCreateNewSubject,
      isModalWindowVisible,
      addNewSubject,
      enterpreneurModel,
      organizationModel,
      closeModal,
      isPersonModalWindowVisible,
      isOrganizationModalWindowVisible,
      isSubjectHasDuplicates,
      enterpreneurValidator,
      organizationValidator,
      onInfoSubjectClick,
      isReadonlySubject,
      isSubjectInfoInactive,
      getTooltip,
      isCreate,
      formatSubjectInfo,
      subjectReferences,
      getActualSubjects,
      getInactualSubjects,
      closeDuplicateModal,
      makeNewSubjectActual,
      isPersonSubjectsList,
      isOrganizationSubjectsList,
      subjectDuplicates,
      chosenSubject,
      groupId
    };
  },
};
</script>

<style lang="scss" scoped>
.subjects__list {
  padding-left: 0;
}

.list__element {
  list-style-type: none;
  margin-bottom: 18px;
}

.subject-select {
  padding-top: 15px;
  padding-bottom: 24px;
  display: inline-flex;
}

.subject-select__radio {
  display: none;
}

.subject-select__label {
  position: relative;
}

.dropdown__item:hover .subjects__display-name {
  font-weight: 400;
  color: var(--basic-black);
}

.dropdown__item:hover .subjects__description {
  color: #90a0b7;
}

.subject-select__radio + label::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--basic-white);
  border: 1px solid var(--secondary-blue-darker);
  left: 0;
  top: 2px;
}

.subject-select__radio:checked + label::before {
  width: 15px;
  height: 15px;
  background-color: var(--primary-blue-lighter);
  border: 2px solid var(--basic-white);
  box-shadow: 0 0 0 1px var(--primary-blue-lighter);
}

.subject-select__radio:checked:hover + label::before {
  background-color: var(--primary-blue);
  border: 2px solid var(--basic-white);
  box-shadow: 0 0 0 1px var(--primary-blue);
}

.subject-select__radio:hover + label::before,
.subject-select__radio:focus + label::before {
  border-color: var(--primary-blue-lighter);
}

.subject-select__label {
  padding-left: 24px;
  font-size: 16px;
  line-height: 20px;
  color: var(--secondary-blue-darker);
}

.subject-select__radio-and-label:first-child {
  margin-right: 40px;
}

.add-subject__icon {
  margin-right: 5px;
}

.add-subject:hover .add-subject__icon path {
  stroke: var(--basic-black);
}

.add-subject {
  position: relative;
  padding-top: 4px;
  padding-bottom: 7px;
  box-sizing: border-box;
}
</style>
